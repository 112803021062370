.catering-tab {
    --primary-color: #2469c2;
    --primary-50-color: #92add1;
    --primary-25-color: #dde7f3;

    --warning-color: #ffda1f;
    --warning-50-color: #ffea7f;
    --warning-25-color: #fff7d2;

    --danger-color: #ff6161;
    --danger-50-color: #ff9d9d;
    --danger-25-color: #ffcbcb;

    /* First Table */
    --col-date-width: 12%;
    --col-order-no-width: 7%;
    --col-costcenter-width: 12%;
    --col-company-width: 7%;
    --col-modebooker-width: 11%;
    --col-meeting-room-width: 13%;
    --col-orders-width: 17%;
    --col-guest-width: 4%;
    --col-internal-width: 6%;
    --col-costs-width: 5%;
    --col-total-cost-width: 7%;
}

.catering-tab .legend-list {
    overflow-x: hidden;
    overflow-y: auto;
}

.catering-tab .legend-list .legend {
    display: flex;
    gap: 6px;
    margin-bottom: 0.5rem;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
}

.catering-tab .legend-list .legend .legend-color {
    height: 16px;
    width: 16px;
    min-width: 16px;
}

.catering-tab .legend-list .legend .legend-info .legend-label,
.catering-tab .legend-list .legend .legend-info .legend-value {
    display: block;
    width: 100%;
    font-size: small;
    line-height: 16px;
    font-size: 12px;
    white-space: nowrap;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.catering-tab .legend-list .legend .legend-info .legend-label {
    margin-bottom: 4px;
    color: gray;
}

.catering-tab .legend-list .legend .legend-info .legend-value {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
}


/* ====================================================== Input Search/Export ====================================================== */
/* ====================================================== Input Search/Export ====================================================== */
.catering-tab .input-user-group .form-control {
    padding: 0 0 0 10px;
    height: 42px;
    font-size: 14px;
    border: 2px solid var(--app-theme-50-color);
    border-radius: 8px;
    background-color: white !important;
    border-right: 0;
    box-shadow: none;
}

.catering-tab .input-user-group .form-control::-webkit-input-placeholder {
    color: var(--app-theme-50-color);
}

.catering-tab .input-user-group .input-group-text {
    font-size: 14px;
    height: 42px;
    border: 2px solid var(--app-theme-50-color);
    border-radius: 8px;
    background-color: white !important;
    border-left: 0;
    color: var(--app-theme-color);
}

.catering-tab .btn-download {
    height: 42px;
    width: 42px;
    border: 2px solid var(--app-theme-50-color);
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 18px;
    color: var(--app-theme-color);
    background-color: #fff;
}

.catering-tab .btn-download i::before {
    font-weight: bold !important;
}

/* ------------------------------------------------------ Input Search/Export ------------------------------------------------------ */

.catering-tab .extra-width-row {
    width: 100%;
}

.catering-tab .extra-width-row hr {
    height: 0 !important;
    border-bottom: 2px solid var(--app-theme-50-color);
    margin: 0 0 16px 0;
}

@media (min-width: 1200px) {
    .catering-tab .extra-width-row {
        width: calc(120% + 0.5rem);
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .catering-tab .extra-width-row {
        width: calc(133.33% + 0.5rem);
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .catering-tab .extra-width-row {
        width: calc(150% + 0.5rem);
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .catering-tab .extra-width-row {
        width: 100%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .catering-tab .col-lg-0 {
        width: 0;
        padding: 0 !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .catering-tab .col-md-0 {
        width: 0;
        padding: 0 !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .catering-tab .col-sm-0 {
        width: 0;
        padding: 0 !important;
    }
}

.catering-tab .catering-table-card {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
}

.catering-tab .catering-table-card .no-record-found {
    border-left: 2px solid var(--app-theme-50-color);
}

.catering-tab .catering-table-card .accordion-button::after {
    display: none;
}

.catering-tab .catering-table-card .col-date,
.catering-tab .catering-table-card .col-order-no,
.catering-tab .catering-table-card .col-costcenter,
.catering-tab .catering-table-card .col-company,
.catering-tab .catering-table-card .col-modebooker,
.catering-tab .catering-table-card .col-meeting-room,
.catering-tab .catering-table-card .col-orders,
.catering-tab .catering-table-card .col-guest,
.catering-tab .catering-table-card .col-internal,
.catering-tab .catering-table-card .col-costs,
.catering-tab .catering-table-card .col-total-cost {
    height: 100%;
}

.catering-tab .catering-table-card .col-date {
    width: var(--col-date-width);
}

.catering-tab .catering-table-card .col-order-no {
    width: var(--col-order-no-width);
}

.catering-tab .catering-table-card .col-costcenter {
    width: var(--col-costcenter-width);
}

.catering-tab .catering-table-card .col-company {
    width: var(--col-company-width);
}

.catering-tab .catering-table-card .col-modebooker {
    width: var(--col-modebooker-width);
}

.catering-tab .catering-table-card .col-meeting-room {
    width: var(--col-meeting-room-width);
}

.catering-tab .catering-table-card .col-orders {
    width: var(--col-orders-width);
}

.catering-tab .catering-table-card .col-guest {
    width: var(--col-guest-width);
}

.catering-tab .catering-table-card .col-internal {
    width: var(--col-guest-width);
}

.catering-tab .catering-table-card .col-costs {
    width: var(--col-costs-width);
}

.catering-tab .catering-table-card .col-total-cost {
    width: var(--col-total-cost-width);
}


/* ==== Table Main Header ====  */
.catering-tab .catering-table-card .catering-table-card-header {
    display: flex;
    background-color: transparent;
}

.catering-tab .catering-table-card .catering-table-card-header .title {
    margin: 0 0 15px 0;
    width: 100%;
    font-size: 14px;
    padding: 10px 0;
    font-weight: 600;
    padding-right: 8px;
}

/* ---- Table Main Header ----  */

.catering-tab .catering-table-card .accordion-registration {
    max-height: calc(100vh - 160px);
    overflow: auto;
    border-radius: 8px;

    background-color: white;
    border: 2px solid var(--app-theme-50-color);
    /* border-bottom: 2px solid var(--app-theme-50-color);
    border-top: 2px solid var(--app-theme-50-color); */
}

.catering-tab .catering-table-card .accordion .accordion-item {
    border-right: none;
    border-top: none;
    border-bottom: 2px solid var(--app-theme-50-color);
}

.catering-tab .catering-table-card .accordion-registration .col-date {
    display: flex;
    align-items: center;
}

.catering-tab .catering-table-card .accordion-registration .col-date,
.catering-tab .catering-table-card .accordion-registration .col-order-no,
.catering-tab .catering-table-card .accordion-registration .col-costcenter,
.catering-tab .catering-table-card .accordion-registration .col-company,
.catering-tab .catering-table-card .accordion-registration .col-modebooker,
.catering-tab .catering-table-card .accordion-registration .col-meeting-room,
.catering-tab .catering-table-card .accordion-registration .col-orders,
.catering-tab .catering-table-card .accordion-registration .col-guest,
.catering-tab .catering-table-card .accordion-registration .col-internal,
.catering-tab .catering-table-card .accordion-registration .col-costs,
.catering-tab .catering-table-card .accordion-registration .col-total-cost {
    /* height: 55px; */
    /* height: min-content; */
    /* margin: auto; */
    /* display: flex; */
    /* align-items: center; */
    /* padding-right: 5px; */
    overflow: hidden;
}

.catering-tab .catering-table-card .accordion-registration .col-date span,
.catering-tab .catering-table-card .accordion-registration .col-order-no span,
.catering-tab .catering-table-card .accordion-registration .col-costcenter span,
.catering-tab .catering-table-card .accordion-registration .col-company span,
.catering-tab .catering-table-card .accordion-registration .col-modebooker span,
.catering-tab .catering-table-card .accordion-registration .col-meeting-room span,
.catering-tab .catering-table-card .accordion-registration .col-orders span,
.catering-tab .catering-table-card .accordion-registration .col-guest span,
.catering-tab .catering-table-card .accordion-registration .col-internal span,
.catering-tab .catering-table-card .accordion-registration .col-costs span,
.catering-tab .catering-table-card .accordion-registration .col-total-cost span {
    color: var(--app-theme-color);
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
}

/* .catering-tab .catering-table-card .accordion-registration .col-date i.bi-chevron-down {
    color: var(--app-theme-50-color);
}

.catering-tab .catering-table-card .accordion-registration .col-date i.bi-chevron-up {
    color: var(--app-theme-color);
} */

.catering-tab .catering-table-card .accordion-registration .col-date i.bi-chevron {
    color: var(--app-theme-color) !important;
}

.catering-tab .catering-table-card .accordion-registration .col-date i {
    margin-right: 20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.catering-tab .catering-table-card .accordion-registration .col-date i::before {
    font-weight: 600 !important;
}

.catering-tab .catering-table-card .accordion .accordion-item:last-of-type {
    border-bottom: none;
}

.catering-tab .accordion-registration .accordion-button {
    padding: 16px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
    align-items: flex-start;
}

/* .catering-tab .accordion-registration .accordion-button:not(.collapsed) {
    background-color: var(--app-theme-25-color);
    box-shadow: inset 0 -1px var(--app-theme-50-color);
} */

.catering-tab .accordion-first>.accordion-item {
    border-radius: 0;
    /* border-left: 2px solid var(--app-theme-50-color); */
}

.catering-tab .accordion-first>.accordion-item.open {
    border-left: 4px solid var(--app-theme-color);
}

.caterin-bottom-bar {
    /* color: var(--app-theme-color); */
    padding-right: "auto";
    /* font-weight: 500; */
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
}