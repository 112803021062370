.prev,
.prev:focus,
.next,
.next:focus {
    background-color: var(--app-theme-color);
    border-color: var(--app-theme-color);
    box-shadow: none !important;
}

.prev:hover,
.next:hover {
    background-color: var(--app-theme-25-color);
    border-color: var(--app-theme-50-color);
    color: var(--app-theme-color);
}

.prev:target,
.next:target {
    border-color: var(--app-theme-25-color);
}

.prev:disabled,
.next:disabled {
    background-color: gray;
    border-color: gray;
}