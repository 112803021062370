.card.heat-map {
  position: relative;
}

.heat-map .main-map-area {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.heat-map .main-map-area .main-map {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.heat-map .main-map-area .loader {
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heat-map .tool-tip-wrapper {
  border-radius: 5px;
  background-color: white;
  position: absolute;
  margin-top: 47px;
  transition: all 0.5s;
}

.heat-map .tool-tip-wrapper:hover {
  z-index: 50;
  margin-top: 0;
}

.heat-map .tool-tip-wrapper .tool-tip {
  padding: 6px;
  border-radius: 5px;
  max-width: 190px;
  min-width: 190px;
  cursor: pointer;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
}

.heat-map .tool-tip-wrapper .tool-tip .tool-tip-details-section {
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
}

.heat-map .tool-tip-wrapper:hover .tool-tip .tool-tip-details-section {
  height: 47px;
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-primary {
  background-color: var(--app-theme-25-color);
  border: 1px solid var(--app-theme-50-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-success {
  background-color: var(--app-tooltip-success-background-color);
  border: 1px solid var(--app-tooltip-success-border-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-warning {
  background-color: var(--app-tooltip-warning-background-color);
  border: 1px solid var(--app-tooltip-warning-border-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-danger {
  background-color: var(--app-tooltip-danger-background-color);
  border: 1px solid var(--app-tooltip-danger-border-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-primary::before,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-success::before,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-warning::before,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-danger::before {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  background: white;
  position: absolute;
  bottom: -6px;
  left: 10px;
  transform: rotate(225deg);
  border-top: 1px solid white;
  border-left: 1px solid white;
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-primary::after,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-success::after,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-warning::after,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-danger::after {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: -6px;
  left: 10px;
  transform: rotate(225deg);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-primary::after {
  background: var(--app-theme-25-color);
  border-top: 1px solid var(--app-theme-50-color);
  border-left: 1px solid var(--app-theme-50-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-success::after {
  background: var(--app-tooltip-success-background-color);
  border-top: 1px solid var(--app-tooltip-success-border-color);
  border-left: 1px solid var(--app-tooltip-success-border-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-warning::after {
  background: var(--app-tooltip-warning-background-color);
  border-top: 1px solid var(--app-tooltip-warning-border-color);
  border-left: 1px solid var(--app-tooltip-warning-border-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-danger::after {
  background: var(--app-tooltip-danger-background-color);
  border-top: 1px solid var(--app-tooltip-danger-border-color);
  border-left: 1px solid var(--app-tooltip-danger-border-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-primary .tool-tip-details .badge,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-success .tool-tip-details .badge,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-warning .tool-tip-details .badge,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-danger .tool-tip-details .badge {
  color: white !important;
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-primary .tool-tip-details .badge {
  background-color: var(--app-theme-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-success .tool-tip-details .badge {
  background-color: var(--app-tooltip-success-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-warning .tool-tip-details .badge {
  background-color: var(--app-tooltip-warning-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-danger .tool-tip-details .badge {
  background-color: var(--app-tooltip-danger-color);
}

.heat-map .tool-tip-wrapper .tool-tip .title {
  width: 100%;
  font-size: small;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.heat-map .tool-tip-wrapper .tool-tip .tool-tip-details-section .tool-tip-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(44, 44, 44);
}

.heat-map .tool-tip-wrapper .tool-tip .tool-tip-details .data-label,
.heat-map .tool-tip-wrapper .tool-tip .tool-tip-details .data-value {
  font-size: 10px;
  font-weight: 600;
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-primary .usage,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-primary .data-value.highlighted {
  color: var(--app-theme-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-success .usage,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-success .data-value.highlighted {
  color: var(--app-tooltip-success-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-warning .usage,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-warning .data-value.highlighted {
  color: var(--app-tooltip-warning-color);
}

.heat-map .tool-tip-wrapper .tool-tip.tool-tip-danger .usage,
.heat-map .tool-tip-wrapper .tool-tip.tool-tip-danger .data-value.highlighted {
  color: var(--app-tooltip-danger-color);
}

.heat-map .tool-tip-wrapper:hover .usage {
  display: none;
}

/* ============================================================ Area Plan Full Screen Modal ============================================================ */
.card-modal {
  --card-modal-height: 100vh;
  --card-modal-border-radius: 0rem;
  --card-modal-padding: 0rem;

  --modal-header-height: 60px;
  --modal-body-height: calc(var(--card-modal-height) - var(--modal-header-height) - 2 * var(--card-modal-padding));

  position: fixed;
  top: 0;
  right: 0;
  padding: var(--card-modal-padding);
  height: var(--card-modal-height);
  width: 80%;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 500;
  border-left: 1px solid #cecece;
  box-shadow: 0 0px 7px 5px rgba(80, 80, 80, 0.08);
}

@media screen and (max-width: 480px) {
  .card-modal {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 991px) {
  .card-modal {
    width: calc(70% - 20px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .card-modal {
    width: calc(78% - 20px);
  }
}

@media screen and (min-width: 1201px) {
  .card-modal {
    width: calc(85% - 20px);
  }
}

.card-modal.open {
  display: block;
}

.card-modal .modal-header {
  height: var(--modal-header-height);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  color: var(--app-theme-color);
  background-color: #fff;

  border-top-left-radius: var(--card-modal-border-radius);
  border-top-right-radius: var(--card-modal-border-radius);
}

.card-modal .modal-header button,
.card-modal .modal-header i {
  cursor: pointer;
}

.card-modal .modal-header .title {
  max-width: 200px;
  min-width: 150px;
  color: rgba(0, 0, 0, 0.8);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.card-modal .modal-header .title:hover {
  max-width: 100%;
}

.card-modal .modal-header .modal-close {
  position: absolute;
  font-size: 1.75rem;
  right: calc(var(--modal-header-height) / 2 - 1.75rem / 2);
  top: calc(var(--modal-header-height) / 2 - 1.75rem / 2);
}

.card-modal .modal-body {
  height: var(--modal-body-height);
  width: 100%;
  padding: 0;
  background-color: #fff;
  border-bottom-left-radius: var(--card-modal-border-radius);
  border-bottom-right-radius: var(--card-modal-border-radius);
  display: flex;
}

.card-modal .modal-body {
  height: var(--modal-body-height);

  display: flex;
  align-items: center;
  justify-content: center;
}

.card-modal .modal-body .map-loader {
  height: var(--modal-body-height);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-modal .modal-body .map-area {
  width: auto;
  max-height: var(--modal-body-height);
  max-width: 100%;
  position: relative;
}

.card-modal .modal-body .map-area .map {
  max-height: var(--modal-body-height);
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-modal .modal-body .map-area .seats {
  width: 40px;
  height: 26px;
  border-radius: 5px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
}

.card-modal .modal-body .map-area .seats:hover {
  z-index: 52;
}

.card-modal .modal-body .map-area .seats.seat-primary {
  background-color: var(--app-theme-color);
}

.card-modal .modal-body .map-area .seats.seat-success {
  background-color: var(--app-tooltip-success-color);
}

.card-modal .modal-body .map-area .seats.seat-warning {
  background-color: var(--app-tooltip-warning-color);
}

.card-modal .modal-body .map-area .seats.seat-danger {
  background-color: var(--app-tooltip-danger-color);
}

.card-modal .modal-body .map-area .seats .seat-data {
  font-size: 10px;
  font-weight: 600;
  color: white;
}