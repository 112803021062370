.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  background: url("../assets/img/icon-calendar.svg") no-repeat right;
  background-size: 25px;
  background-origin: content-box;
  padding-right: 10px;
}

.sidebar-filter {
  color: rgb(68, 68, 68);
  font-size: 12px;
  font-style: italic;
}

.sidebar-filter.selected {
  color: var(--app-theme-color);
  font-weight: 600;
  font-style: normal;
}

.sidebar-filter:hover {
  cursor: pointer;
  color: var(--app-theme-color);
  font-weight: 600;
}
