:root {
  --app-theme-color: #004195;
  --app-theme-50-color: #C7D9F1;
  --app-theme-25-color: #cbdeff66;

  --app-tooltip-success-color: #05b233;
  --app-tooltip-success-background-color: #deffe7;
  --app-tooltip-success-border-color: #97e6ac;

  --app-tooltip-warning-color: #f3c200;
  --app-tooltip-warning-background-color: #fffbeb;
  --app-tooltip-warning-border-color: #f5e295;

  --app-tooltip-danger-color: #dc0202;
  --app-tooltip-danger-background-color: #ffefef;
  --app-tooltip-danger-border-color: #f6cfce;
}

#root {
  background-color: #F2F5F9;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#main {
  min-height: calc(100vh - 60px);
}