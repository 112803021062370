.header .rounded-circle {
  display: flex-end;
  justify-content: center;
  height: 36px;
  width: 36px;
  overflow: hidden;
  background-color: white;
}

.header .rounded-circle i {
  font-size: 30px;
  color: gray;
}

.header .dropdown .nav-link {
  cursor: pointer;
}

.header .dropdown .dropdown-toggle::after {
  display: none;
}

.header .dropdown .dropdown-menu {
  z-index: 1000;
}

.header .dropdown .dropdown-menu li.dropdown-item {
  cursor: pointer;
}

.header .dropdown .dropdown-menu li:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.header .dropdown .dropdown-menu-arrow::before {
  right: 12px;
}

.header .dropdown .dropdown-menu .user-name {
  color: rgba(0, 0, 0, 0.8);
}

/* ======================= Switch Tenant Modal ======================= */
.header .modal .modal-content {
  --modal-border-radius: 8px;
  border-radius: var(--modal-border-radius);
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.header .modal .modal-header {
  background-color: var(--app-theme-color);
  color: white;
}

.header .modal .modal-header i {
  padding: 5px;
  cursor: pointer;
}

.header .modal .modal-body {
  padding: 1.5rem 2.5rem;
}

.header .modal .modal-body .input-label {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.header .modal .modal-body .form-select {
  padding: 8px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: none;
}

.header .modal .modal-footer .btn-primary {
  background-color: var(--app-theme-color);
  color: white;
}

/* ----------------------- Switch Tenant Modal ----------------------- */