.counts {
  background-color: var(--app-theme-25-color) !important;
  color: 1px solid var(--app-theme-50-color) !important;
}

.counts .digit {
  color: var(--app-theme-color) !important;
}

.nav-link.active {
  background-color: var(--app-theme-color) !important;
}