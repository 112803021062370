.work-time-tab {
    --primary-color: #2469c2;
    --primary-50-color: #92add1;
    --primary-25-color: #dde7f3;

    --warning-color: #ffda1f;
    --warning-50-color: #ffea7f;
    --warning-25-color: #fff7d2;

    --danger-color: #ff6161;
    --danger-50-color: #ff9d9d;
    --danger-25-color: #ffcbcb;

    /* First Table */
    --col-employee-width: 25%;
    --col-total-hrs-width: 10%;
    --col-rest-time-width: 20%;
    --col-working-hrs-width: 10%;
    --col-out-of-office-width: 10%;
    --col-registration-status-width: 12%;
    --col-actions-width: 13%;

    /* Second And Third Table */
    --col-employee-wide-width: 35%;
    --col-violation-width: 35%;
    --col-status-width: 30%;
}

/* ====================================================== Top Cards ====================================================== */
/* ====================================================== Top Cards ====================================================== */
.work-time-tab .scroll-view {
    display: flex;
    align-items: center;
    overflow-y: auto;
    flex: 1;
    scroll-snap-type: x mandatory;
}

.work-time-tab .form-select {
    border-radius: 8px;
    border: 2px solid var(--app-theme-50-color);
    box-shadow: none;
    height: 42px;
    padding: 9px;
}

/* width */
.work-time-tab .scroll-view::-webkit-scrollbar {
    height: 0;
}

.work-time-tab .btn-navigate {
    background-color: white;
    height: 135px;
    border-radius: 8px;
    border: 2px solid var(--primary-25-color) !important;
    color: var(--primary-color);
    box-shadow: none;
}

/* @media (min-width: 1200px) {
    .work-time-tab .btn-navigate {
        display: none;
    }
} */

.work-time-tab .card.filter-card {
    padding: 22.5px 15px;
    text-align: center;
    border: 2px solid var(--primary-25-color) !important;
    height: 135px;
    width: 170px;
    min-width: 170px;
    /* flex: 1; */
    margin: 0 10px 0 0;
    scroll-snap-align: center;
}

.work-time-tab .card.filter-card:last-of-type {
    margin: 0;
}

.work-time-tab .card.filter-card .title {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    font-weight: 600;
}

.work-time-tab .card.filter-card .digit-lg {
    font-size: 34px;
    font-weight: 600;
    display: block;
}

.work-time-tab .card.filter-card .digit-sm {
    font-size: 12px;
    font-weight: 600;
    display: block;
}

.work-time-tab .card.filter-card-primary .digit-lg {
    color: var(--primary-color);
}

.work-time-tab .card.filter-card-primary .digit-sm {
    color: var(--primary-50-color);
}

.work-time-tab .card.filter-card-danger .digit-lg {
    color: var(--danger-color);
}

.work-time-tab .card.filter-card-danger .digit-sm {
    color: var(--danger-50-color);
}

.work-time-tab .card.filter-card-warning .digit-lg {
    color: var(--warning-color);
}

.work-time-tab .card.filter-card-warning .digit-sm {
    color: var(--warning-50-color);
}

/* ------------------------------------------------------ Top Cards ------------------------------------------------------ */




/* ====================================================== Table Toggle Button ====================================================== */
/* ====================================================== Table Toggle Button ====================================================== */

.work-time-tab .table-toggle {
    display: flex;
    width: 100%;
    border-radius: 6px;
}

.work-time-tab .table-toggle .switch-table-btn {
    color: var(--app-theme-color);
    margin-right: 1%;
    border: 1px solid var(--app-theme-50-color);
    height: 42px;
    width: calc(100% / 3);
    align-items: center;
    box-shadow: none;
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.work-time-tab .table-toggle .switch-table-btn:hover {
    background-color: var(--app-theme-25-color);
}

.work-time-tab .table-toggle .switch-table-btn.active {
    background-color: var(--app-theme-color);
    border: 2px solid var(--app-theme-color);
    color: white;
}

/* ------------------------------------------------------ Table Toggle Button ------------------------------------------------------ */




/* ====================================================== Input Search/Import ====================================================== */
/* ====================================================== Input Search/Import ====================================================== */
.work-time-tab .input-user-group .form-control {
    padding: 0 0 0 10px;
    height: 42px;
    font-size: 14px;
    border: 2px solid var(--app-theme-50-color);
    border-radius: 8px;
    background-color: white !important;
    border-right: 0;
    box-shadow: none;
}

.work-time-tab .input-user-group .form-control::-webkit-input-placeholder {
    color: var(--app-theme-50-color);
}

.work-time-tab .input-user-group .input-group-text {
    font-size: 14px;
    height: 42px;
    border: 2px solid var(--app-theme-50-color);
    border-radius: 8px;
    background-color: white !important;
    border-left: 0;
    color: var(--app-theme-color);
}

.work-time-tab .btn-download {
    height: 42px;
    width: 42px;
    border: 2px solid var(--app-theme-50-color);
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 18px;
    color: var(--app-theme-color);
    background-color: #fff;
}

.work-time-tab .btn-download i::before {
    font-weight: bold !important;
}

/* ------------------------------------------------------ Input Search/Import ------------------------------------------------------ */




/* ====================================================== WorkTime Table ====================================================== */
/* ====================================================== WorkTime Table ====================================================== */

.work-time-tab .extra-width-row {
    width: 100%;
}

.work-time-tab .extra-width-row hr {
    height: 0 !important;
    border-bottom: 2px solid var(--app-theme-50-color);
    margin: 0 0 16px 0;
}

@media (min-width: 1200px) {
    .work-time-tab .extra-width-row {
        width: calc(120% + 0.5rem);
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .work-time-tab .extra-width-row {
        width: calc(133.33% + 0.5rem);
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .work-time-tab .extra-width-row {
        width: calc(150% + 0.5rem);
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .work-time-tab .extra-width-row {
        width: 100%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .work-time-tab .col-lg-0 {
        width: 0;
        padding: 0 !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .work-time-tab .col-md-0 {
        width: 0;
        padding: 0 !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .work-time-tab .col-sm-0 {
        width: 0;
        padding: 0 !important;
    }
}

.work-time-tab .work-time-table-card {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
}

.work-time-tab .work-time-table-card .no-record-found {
    border-left: 2px solid var(--app-theme-50-color);
}

.work-time-tab .work-time-table-card .accordion-button::after {
    display: none;
}

.work-time-tab .work-time-table-card .col-employee,
.work-time-tab .work-time-table-card .col-total-hrs,
.work-time-tab .work-time-table-card .col-rest-time,
.work-time-tab .work-time-table-card .col-working-hrs,
.work-time-tab .work-time-table-card .col-out-of-office,
.work-time-tab .work-time-table-card .col-registration-status,
.work-time-tab .work-time-table-card .col-actions {
    height: 100%;
}

.work-time-tab .work-time-table-card .col-employee {
    width: var(--col-employee-width);
}

.work-time-tab .work-time-table-card .col-total-hrs {
    width: var(--col-total-hrs-width);
}

.work-time-tab .work-time-table-card .col-rest-time {
    width: var(--col-rest-time-width);
}

.work-time-tab .work-time-table-card .col-working-hrs {
    width: var(--col-working-hrs-width);
}

.work-time-tab .work-time-table-card .col-out-of-office {
    width: var(--col-out-of-office-width);
}

.work-time-tab .work-time-table-card .col-registration-status {
    width: var(--col-registration-status-width);
}

.work-time-tab .work-time-table-card .col-actions {
    width: var(--col-actions-width);
}

.work-time-tab .work-time-table-card .col-violation {
    width: var(--col-violation-width);
}

.work-time-tab .work-time-table-card .col-status {
    width: var(--col-status-width);
}

/* ==== Table Main Header ====  */
.work-time-tab .work-time-table-card .work-time-table-card-header {
    display: flex;
    background-color: transparent;
}

.work-time-tab .work-time-table-card .work-time-table-card-header .title {
    margin: 0 0 15px 0;
    width: 100%;
    font-size: 14px;
    padding: 10px 0;
    font-weight: 600;
    padding-right: 8px;
}

/* ---- Table Main Header ----  */

.work-time-tab .work-time-table-card .accordion-registration {
    max-height: calc(100vh - 160px);
    overflow: auto;
    background-color: white;
    border-right: 2px solid var(--app-theme-50-color);
    border-bottom: 2px solid var(--app-theme-50-color);
    border-top: 2px solid var(--app-theme-50-color);
}

.work-time-tab .work-time-table-card .accordion .accordion-item {
    border-right: none;
    border-top: none;
    border-bottom: 2px solid var(--app-theme-50-color);
}

.work-time-tab .violation-table-card .col-employee {
    width: var(--col-employee-wide-width);
}

.work-time-tab .violation-table-card .col-violation {
    width: var(--col-violation-width);
}

.work-time-tab .violation-table-card .col-status {
    width: var(--col-status-width);
}

.work-time-tab .work-time-table-card .accordion-registration .col-employee,
.work-time-tab .work-time-table-card .accordion-registration .col-total-hrs,
.work-time-tab .work-time-table-card .accordion-registration .col-rest-time,
.work-time-tab .work-time-table-card .accordion-registration .col-working-hrs,
.work-time-tab .work-time-table-card .accordion-registration .col-out-of-office,
.work-time-tab .work-time-table-card .accordion-registration .col-registration-status,
.work-time-tab .work-time-table-card .accordion-registration .col-actions,
.work-time-tab .work-time-table-card .col-violation,
.work-time-tab .work-time-table-card .col-status {
    height: 55px;
    margin: auto;
    display: flex;
    align-items: center;
}

.work-time-tab .work-time-table-card .accordion-registration .col-employee,
.work-time-tab .work-time-table-card .accordion-registration .col-total-hrs span,
.work-time-tab .work-time-table-card .accordion-registration .col-rest-time span,
.work-time-tab .work-time-table-card .accordion-registration .col-working-hrs span,
.work-time-tab .work-time-table-card .accordion-registration .col-out-of-office span,
.work-time-tab .work-time-table-card .accordion-registration .col-violation span,
.work-time-tab .work-time-table-card .accordion-registration .col-status span {
    color: var(--app-theme-color);
    font-weight: 500;
    font-size: 14px;
}

.work-time-tab .work-time-table-card .accordion-registration .col-employee i.bi-chevron-down {
    color: var(--app-theme-50-color);
}

.work-time-tab .work-time-table-card .accordion-registration .col-employee i.bi-chevron-up {
    color: var(--app-theme-color);
}

.work-time-tab .work-time-table-card .accordion-registration .col-employee i {
    margin-right: 20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.work-time-tab .work-time-table-card .accordion-registration .col-employee i::before {
    font-weight: 600 !important;
}

.work-time-tab .work-time-table-card .accordion .accordion-item:last-of-type {
    border-bottom: none;
}

.work-time-tab .accordion-registration .accordion-button {
    padding: 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
}

.work-time-tab .accordion-registration .accordion-button:not(.collapsed) {
    background-color: var(--app-theme-25-color);
    box-shadow: inset 0 -1px var(--app-theme-50-color);
}

.work-time-tab .accordion-first>.accordion-item {
    border-radius: 0;
    border-left: 2px solid var(--app-theme-50-color);
}

.work-time-tab .accordion-first>.accordion-item.open {
    border-left: 4px solid var(--app-theme-color);
}

.work-time-tab .accordion-first>.accordion-item>.accordion-header>.accordion-button .col-employee .user-name {
    color: var(--app-theme-color);
    font-size: 14px;
    font-weight: 600;
    display: block;
}

.work-time-tab .accordion-first>.accordion-item>.accordion-header>.accordion-button .col-employee .user-email {
    color: gray;
    font-size: 12px;
    font-weight: 400;
    display: block;
}

.work-time-tab .accordion-first>.accordion-item>.accordion-header>.accordion-button .col-actions .btn-submit-all,
.work-time-tab .accordion-first>.accordion-item>.accordion-header>.accordion-button .col-actions .btn-notify {
    align-items: center;
    border-radius: 6px;
    box-shadow: none;
    display: flex;
    height: 33.59px;
    justify-content: center;
    min-height: 0 !important;
    min-width: 0 !important;
    padding: 0 5px;
}

.work-time-tab .accordion-first>.accordion-item>.accordion-header>.accordion-button .col-actions .btn-submit-all {
    background-color: #fff;
    border: 2px solid var(--app-theme-50-color);
    color: var(--app-theme-color);
    width: 100px;
}

.work-time-tab .accordion-first>.accordion-item>.accordion-header>.accordion-button .col-actions .btn-notify {
    background-color: #fff;
    border: 2px solid var(--app-theme-50-color);
    color: var(--app-theme-color);
    width: 33.59px;
}

/* ==== Second Level ==== */
.work-time-tab .accordion-first>.accordion-item>.accordion-collapse.collapsed {
    height: 0;
}

.work-time-tab .accordion-first>.accordion-item>.accordion-collapse.collapsed {
    height: auto;
    overflow: hidden;
    transition: height .35s ease;
}

.work-time-tab .accordion-first>.accordion-item>.accordion-collapse>.accordion-body .accordion-button .col-actions .btn-re-open,
.work-time-tab .accordion-first>.accordion-item>.accordion-collapse>.accordion-body .accordion-button .col-actions .btn-submit {
    align-items: center;
    border-radius: 6px;
    box-shadow: none;
    display: flex;
    height: 33.59px;
    justify-content: center;
    min-height: 0 !important;
    min-width: 0 !important;
    padding: 0 5px;
}

.work-time-tab .accordion-first>.accordion-item>.accordion-collapse>.accordion-body .accordion-button .col-actions .btn-re-open {
    background-color: var(--danger-color);
    border: 2px solid var(--danger-color);
    color: white;
    width: 100px;
}

.work-time-tab .accordion-first>.accordion-item>.accordion-collapse>.accordion-body .accordion-button .col-actions .btn-submit {
    background-color: var(--app-theme-color);
    border: 2px solid var(--app-theme-color);
    color: #fff;
    width: 100px;
}

.work-time-tab .badge {
    display: inline-block;
    padding: 0.5rem;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    min-width: 100px;
}

.work-time-tab .badge-success {
    background-color: white;
    color: #28a745;
    border: 1px solid #28a745;
}

.work-time-tab .badge-warning {
    background-color: white;
    color: #ffc107;
    border: 1px solid #ffc107;
}

/* ---- Second Level ---- */

/* ------------------------------------------------------ WorkTime Table ------------------------------------------------------ */




/* ====================================================== Confirm Modal ====================================================== */
/* ====================================================== Confirm Modal ====================================================== */
.react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.confirm-modal {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    min-width: 320px;
}

.confirm-modal .confirm-header {
    padding: 2rem 2rem 0 2rem;
    width: 100%;
}

.confirm-modal .confirm-header h1 {
    color: var(--app-theme-color);
    padding: 0;
    margin-bottom: 0;
    font-size: 30px;
}

.confirm-modal .confirm-body {
    padding: 1.5rem 2rem;
    width: 100%;
}

.confirm-modal .confirm-body p {
    margin: 0;
}

.confirm-modal .confirm-footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0 2rem 2rem 2rem;
    width: 100%;
}

.confirm-modal .confirm-footer .btn-ok,
.confirm-modal .confirm-footer .btn-cancel {
    padding: 8px 15px;
    box-shadow: none;
}

.confirm-modal .confirm-footer .btn-ok {
    background-color: var(--app-theme-color);
    border: 1px solid var(--app-theme-color);
    color: white;
}

.confirm-modal .confirm-footer .btn-cancel {
    border: 1px solid gray;
    background-color: gray;
    color: white;
}

/* ------------------------------------------------------ Confirm Modal ------------------------------------------------------ */