.circular-progress-bar {
  display: inline-flex;
  vertical-align: middle;
  max-height: 100%;
}

.circular-progress-bar circle {
  stroke: #c0c0c0;
  stroke-width: 10px;
  stroke-dasharray: 0;
  fill: none;
}

.circular-progress-bar .meter {
  stroke-width: 10px;
  stroke: #147ad6;
  fill: none;
  transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transform-origin: center center;
  transform: rotate(-90deg) scaleX(-1);
}

.circular-progress-bar text {
  fill: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}
